import { takeLatest, call, put, delay, select } from 'redux-saga/effects'
import { loginRequest } from '@/store/login/actions'
import { login } from '@/store/login/sagas'
import { activeCompanyIdSelector } from '@/store/user/selectors'
import { safeFetch } from '@/services/fetch-api/fetch.api'
import history from '@/services/history'
import errorLoggingService from '@/services/error.logging.service'
import featureFlagsService from '@/services/feature-flags/feature.flags.service'
import * as createSubscriptionActions from './actions'
import { CREATE_SUBSCRIPTION_REQUEST } from './constants'

const DELAY_TIMEOUT_MILLIS = 1000
const RETRY_DELAY_MILLIS = 2000
const RETRY_LIMIT = 30

/**
 *
 */
export function* getCompany() {
	let apiResponse
	let count = RETRY_LIMIT
	let isValid = false
	while (count > 0 && !isValid) {
		apiResponse = yield call(safeFetch, 'getUser')
		isValid = apiResponse.response.ok && apiResponse.data.companies.length !== 0
		if (!isValid) {
			yield delay(RETRY_DELAY_MILLIS)
			count -= 1
		}
	}

	if (apiResponse.response.ok && apiResponse.data.companies.length !== 0) {
		return apiResponse.data.companies[0].id
	}
	return null
}

/**
 *
 */
export function* createCompany(action) {
	const { hostedPageId, password } = action
	const apiResponse = yield call(safeFetch, 'createCompany', {
		hostedPageId,
		password,
	})
	return apiResponse.response.ok ? apiResponse.data.id : null
}

/**
 * @param action
 */
export function* createSubscription(action) {
	const { payload } = action
	const { addToActiveCompany, companyData, hostedPageId } = payload

	if (addToActiveCompany) {
		const { response } = yield call(safeFetch, 'createFreeSubscription')

		if (response.ok) {
			const activeCompanyId = yield select(activeCompanyIdSelector)
			yield put(
				createSubscriptionActions.createSubscriptionSuccess({
					companyId: activeCompanyId,
				}),
			)
		} else {
			errorLoggingService.leaveBreadcrumb('cannot create free subscription', {
				payload,
				response,
			})
			yield put(createSubscriptionActions.createSubscriptionError())
		}
	} else {
		const { email, password } = companyData

		let companyId
		if (featureFlagsService.getFlag('company-creation-webhook', false)) {
			companyId = yield call(getCompany)
		} else {
			companyId = yield call(createCompany, { hostedPageId, password })

			yield delay(DELAY_TIMEOUT_MILLIS)
		}

		if (companyId) {
			yield put(
				createSubscriptionActions.createSubscriptionSuccess({ companyId }),
			)
			yield call(login, loginRequest({ username: email, password }))
			yield call([history, 'replace'], {
				pathname: `/${companyId}/building`,
				search: '?newUser=true',
			})
		} else {
			errorLoggingService.leaveBreadcrumb('cannot create subscription', {
				payload,
			})
			errorLoggingService.notify('Cannot create account')
			yield put(createSubscriptionActions.createSubscriptionError())
		}
	}
}

/**
 *
 */
export function* createSubscriptionRequestWatcher() {
	yield takeLatest(CREATE_SUBSCRIPTION_REQUEST, createSubscription)
}
