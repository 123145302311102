import { createAction } from '@/utils/actions'
import * as loginActionTypes from './constants'

export const loginRequest = createAction(loginActionTypes.LOGIN_REQUEST)
export const logout = createAction(loginActionTypes.LOGOUT)
export const loginError = createAction(loginActionTypes.LOGIN_ERROR)
export const loginSuccess = createAction(loginActionTypes.LOGIN_SUCCESS)

/**
 * The authentication request performs user authentication and stores the acquired
 * tokens. Unlike the login request, it does not trigger an immediate navigation to
 * the main application screen. This strategy is employed within the user registration
 * flow subsequent to the account creation. This allows polling of the user's newly
 * created company. Upon confirmation of successful creation, the application proceeds
 * to the main screen.
 */
export const authenticationRequest = createAction(
	loginActionTypes.AUTHENTICATION_REQUEST,
)
export const authenticationError = createAction(
	loginActionTypes.AUTHENTICATION_ERROR,
)
export const authenticationSuccess = createAction(
	loginActionTypes.AUTHENTICATION_SUCCESS,
)
