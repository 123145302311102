import { all } from 'redux-saga/effects'

import { addBuildingRequestWatcher } from './add-building/sagas'
import { analyticsWatcher } from './analytics/sagas'
import { applicationRequestWatcher } from './application/sagas'
import { buildingRequestWatcher } from './building/sagas'
import { buildingsListRequestWatcher } from './building-list/sagas'
import { bulkFunctionUpdateWatcher } from './bulk-functions-update/sagas'
import { companyRequestWatcher } from './company/sagas'
import { companyTagsRequestWatcher } from './company-tags/sagas'
import { companyUsersRequestWatcher } from './company-users/sagas'
import { createSubscriptionRequestWatcher } from './create-subscription/sagas'
import { createAccountRequestWatcher } from './account-creation/sagas'
import { deleteAlertRequestWatcher } from './delete-alert/sagas'
import { deleteBuildingRequestWatcher } from './building-delete/sagas'
import { editCompanyUserRequestWatcher } from './edit-company-user/sagas'
import { forgotPasswordRequestWatcher } from './forgot-password/sagas'
import { getCompanyUserSingleRequestWatcher } from './get-company-user-single/sagas'
import { invitationAcceptRequestWatcher } from './accept-invite/sagas'
import { inviteUserRequestWatcher } from './invite-user/sagas'
import {
	loginRequestWatcher,
	logoutWatcher,
	authenticationRequestWatcher,
} from './login/sagas'
import { registerThermostatRequestWatcher } from './register-thermostat/sagas'
import { resetPasswordRequestWatcher } from './reset-password/sagas'
import { revokeUserRequestWatcher } from './revoke-user/sagas'
import { routesWatcher } from './routes/sagas'
import { runtimeReportRequestWatcher } from './runtime-reports/sagas'
import { thermostatListRequestWatcher } from './thermostat-list/sagas'
import { thermostatRequestWatcher } from './thermostat/sagas'
import { toastWatcher } from './toast/sagas'
import { updateSensorRequestWatcher } from './update-sensor/sagas'
import { userRequestWatcher } from './user/sagas'
import { validateEmailRequestWatcher } from './validate-email/sagas'
import { validationTokenRequestWatcher } from './validation-token/sagas'

/**
 *
 */
export default function* rootSaga() {
	yield all([
		addBuildingRequestWatcher(),
		analyticsWatcher(),
		applicationRequestWatcher(),
		buildingRequestWatcher(),
		buildingsListRequestWatcher(),
		bulkFunctionUpdateWatcher(),
		companyRequestWatcher(),
		companyTagsRequestWatcher(),
		companyUsersRequestWatcher(),
		createSubscriptionRequestWatcher(),
		createAccountRequestWatcher(),
		deleteAlertRequestWatcher(),
		deleteBuildingRequestWatcher(),
		editCompanyUserRequestWatcher(),
		forgotPasswordRequestWatcher(),
		getCompanyUserSingleRequestWatcher(),
		invitationAcceptRequestWatcher(),
		inviteUserRequestWatcher(),
		loginRequestWatcher(),
		logoutWatcher(),
		authenticationRequestWatcher(),
		registerThermostatRequestWatcher(),
		resetPasswordRequestWatcher(),
		revokeUserRequestWatcher(),
		routesWatcher(),
		runtimeReportRequestWatcher(),
		thermostatListRequestWatcher(),
		thermostatRequestWatcher(),
		toastWatcher(),
		updateSensorRequestWatcher(),
		userRequestWatcher(),
		validateEmailRequestWatcher(),
		validationTokenRequestWatcher(),
	])
}
